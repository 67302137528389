import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TopNavPortal from '../../components/layout/top-menu/TopNavPortal';
import { TabStrip } from '../../components/shared/tab-strip/TabStrip';
import Profile from '../../components/user/Profile';
import EmailPreferences from '../preferences/EmailPreferences';
import { useTopNavHeading } from '../../global-state/Workspace';
import { useCurrentClient } from '../../global-state/Clients';

const Settings: FC = () => {
  const { t } = useTranslation('user-settings');
  const setTopNavheading = useTopNavHeading((x) => x.setValue);
  const currentClient = useCurrentClient((x) => x.value);

  useEffect(() => {
    if (!currentClient) {
      setTopNavheading(' ');
    }
    return () => {
      setTopNavheading('');
    };
  }, [currentClient, setTopNavheading]);

  return (
    <div className="h-full">
      <TabStrip headerClassName="-mb-2.5" contentClassName="bg-background-1" enableHash enableSticky wrapper={TopNavPortal}>
        <TabStrip.TabHeader id="details" text={t('tabs.details')} value={null} data-cy="user-tab" />
        <TabStrip.TabHeader id="email-preferences" text={t('tabs.email-preferences')} value={null} data-cy="email-preferences-tab" />

        <TabStrip.TabContent forId="details" data-cy="user-tab-content">
          <Profile />
        </TabStrip.TabContent>
        <TabStrip.TabContent forId="email-preferences" data-cy="email-preferences-tab-content">
          <div className="px-6">
            <EmailPreferences />
          </div>
        </TabStrip.TabContent>
      </TabStrip>
    </div>
  );
};

export default Settings;
